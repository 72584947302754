'use client';
import type { DecoratedNextImageT } from 'src/components/image/DecoratedNextImage';
import type { IHeroFields, IVideoFields } from 'types/generated/contentful';
import React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { ActionGroup } from '../actions/ActionGroup';
import type { SectionT } from '../layout';
import { Limit } from '../layout';
import type { ThemeT } from '../Theme';
import type { MotionT } from '../Motion';
import { Badge, H1, Subheading } from '../typography';
import type { ActionT } from '../actions/Action';
import { CenterHero } from './CenterHero';
import { FullCenterHero } from './FullCenterHero';
import { FullHero } from './FullHero';
import { RowHero } from './RowHero';

export type FullHeroT = Omit<CommonHeroT, 'image'> & {
  image?: { src: string } | undefined;
};

export type CommonHeroT = HeroLayoutT & {
  badge?: string | undefined;
  heading?: string | undefined;
  subheading?: React.ReactNode;
};

export type FullHeroLayoutT = Omit<HeroLayoutT, 'image'> & {
  image?: { src: string };
};

export type HeroLayoutT = SectionT &
  ThemeT & {
    image?: DecoratedNextImageT | undefined;
    motion?: MotionT | undefined;
    video?: IVideoFields | undefined;
    layout?: IHeroFields['layout'] | undefined;
    invertLayout?: boolean | undefined;
    children?: React.ReactNode;
    imageMaxWidth?: number | undefined;
    actions?: Array<ActionT> | undefined;
  };

interface HeroContentProps {
  children?: React.ReactNode;
  invertLayout: boolean | undefined;
  actions: ActionT[] | undefined;
  heading: string | undefined;
  subheading: React.ReactNode;
  badge?: string | undefined;
  layout: (CommonHeroT | FullHeroT)['layout'];
}

export const HeroContent = ({
  layout,
  badge,
  heading,
  subheading,
  actions,
  invertLayout,
  children,
}: HeroContentProps) => {
  const rowLayout = layout === 'row';

  return (
    <Box
      flex={['1 1 auto', rowLayout ? '1 1 50%' : '1 1 auto']}
      alignItems={rowLayout ? ['center', 'flex-start'] : 'center'}
      mr={rowLayout && !invertLayout ? ['space0', 'space4'] : 'space0'}
      ml={rowLayout && invertLayout ? ['space0', 'space4'] : 'space0'}
    >
      {badge && <Badge mb="space4">{badge}</Badge>}
      <H1 textAlign={rowLayout ? ['center', 'left'] : 'center'} mb="space0">
        {/* eslint-disable-next-line menti-react-jsx/no-possibly-undefined-in-jsx */}
        {heading}
      </H1>
      {subheading && (
        <Limit alignItems={rowLayout ? ['center', 'flex-start'] : 'center'}>
          <Subheading
            textAlign={rowLayout ? ['center', 'left'] : 'center'}
            mt="space4"
          >
            {subheading}
          </Subheading>
        </Limit>
      )}
      {children}
      {actions && (
        <Box mt="space8">
          <ActionGroup actions={actions} />
        </Box>
      )}
    </Box>
  );
};

export const Hero = (props: CommonHeroT | FullHeroT) => {
  const {
    actions,
    badge,
    heading,
    layout = 'center',
    subheading,
    children,
    ...rest
  } = props;
  const heroContent = (
    <HeroContent
      badge={badge}
      layout={layout}
      actions={actions}
      heading={heading}
      subheading={subheading}
      invertLayout={rest.invertLayout}
    >
      {children}
    </HeroContent>
  );

  if (layout === 'center')
    return <CenterHero {...(rest as HeroLayoutT)}>{heroContent}</CenterHero>;

  if (layout === 'row')
    return <RowHero {...(rest as HeroLayoutT)}>{heroContent}</RowHero>;

  if (layout === 'full')
    return <FullHero {...(rest as FullHeroLayoutT)}>{heroContent}</FullHero>;

  if (layout === 'full center')
    return (
      <FullCenterHero {...(rest as FullHeroLayoutT)}>
        {heroContent}
      </FullCenterHero>
    );

  return null;
};
