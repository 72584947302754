'use client';
import React, { type ReactNode } from 'react';
import Image from 'next/image';
import { yellow100 } from '@mentimeter/ragnar-colors';
import { useMatch } from '@mentimeter/ragnar-device';
import { Section, Wide } from 'src/ui/layout';
import { H2, H4, Subheading } from 'src/ui/typography';
import { ContactUs } from 'src/components/ContactUs';
import cloudflareLoader from 'imageLoader';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import { List } from '@mentimeter/ragnar-ui/list';
import { Button } from '@mentimeter/ragnar-ui/button';

interface FullColorBlockProps {
  strong: string;
  title: string;
  cta: string;
  subtitle: string;
  bullets: { text: string; icon: ReactNode }[];
  imageUrl?: string;
  backgroundColor?: string;
  ctaHref?: string;
  withModal?: boolean;
}
const FullColorBlock = ({
  strong,
  title,
  cta,
  subtitle,
  bullets,
  withModal,
  imageUrl = 'https://static.mentimeter.com/static/images/EnterpriseBlockBackground.png',
  ctaHref = '/enterprise',
  backgroundColor = yellow100,
}: FullColorBlockProps) => {
  const isDesktop = useMatch({ greaterThan: 2 });

  return (
    <Section px={['space0', null, 'space8', 6]}>
      <Wide>
        <Box
          flexDirection="row"
          bg={backgroundColor}
          width="100%"
          height={['474px', '474px', '542px']}
          borderRadius={[0, 0, '4xl']}
          justifyContent="center"
          p={['space4', null, 'space16']}
        >
          <Box
            maxWidth={['none', '550px', '420px']}
            justifyContent="space-around"
            height="100%"
            flex="0 1 100%"
            px={['space8', 'space0']}
          >
            <H4 fontSize="87.5" lineHeight="100" mb="space0">
              {strong}
            </H4>
            <H2
              fontSize={['200', '200', '300']}
              fontWeight="regular"
              extend={() => ({
                lineHeight: '1 !important',
              })}
              mb="space0"
            >
              {title}
            </H2>
            <Subheading fontSize="125">{subtitle}</Subheading>
            <List
              extend={() => ({
                '> div li': {
                  listStyleType: 'none',
                },
              })}
            >
              {bullets?.map((bullet, index) => (
                <Box
                  as="li"
                  flexDirection="row"
                  gap={2}
                  key={bullet.text + index}
                >
                  <Box mt="1px">{bullet.icon}</Box>
                  <Text>{bullet.text}</Text>
                </Box>
              ))}
            </List>
            {withModal ? (
              <ContactUs variant="primary" size="default" text={cta} />
            ) : (
              <Button variant="primary" href={ctaHref}>
                {cta}
              </Button>
            )}
          </Box>
          {isDesktop && (
            <Box
              mt="-space16"
              mr="-space16"
              alignItems="flex-end"
              borderTopRightRadius="4xl"
              minHeight="542px"
              flex="1 1 100%"
            >
              <Image
                loader={cloudflareLoader}
                src={imageUrl}
                alt="Man and woman laughing"
                width={532}
                height={542}
                style={{
                  borderTopRightRadius: '32px',
                  objectFit: 'cover',
                }}
              />
            </Box>
          )}
        </Box>
      </Wide>
    </Section>
  );
};

export default FullColorBlock;
