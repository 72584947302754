import Image from 'next/image';
import { Wide, Section } from 'src/ui/layout';
import { H2 } from 'src/ui/typography';
import Tag from 'src/components/Tag';
import cloudflareLoader from 'imageLoader';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { P } from '@mentimeter/ragnar-ui/typography';

export interface HalfColorBlockProps {
  image: { src: string; alt: string };
  reverse: boolean;
  info: {
    tag: string;
    title: string;
    author: {
      name: string;
      role: string;
    };
    cta: {
      text: string;
      url: string;
    };
  };
}
const HalfColorBlock = ({ image, reverse, info }: HalfColorBlockProps) => {
  return (
    <Section px={['space8', null, null, 6]}>
      <Wide
        flexDirection={[
          'column-reverse',
          'column-reverse',
          reverse ? 'row' : 'row-reverse',
        ]}
        alignItems={['center', 'center', 'stretch']}
      >
        <Box flex="1 1 100%" alignItems="center">
          <Box
            justifyContent={['center', 'center', 'space-around']}
            height="100%"
            gap={4}
            maxWidth={['600px', '600px', '400px']}
            mt={['-space5', '-space5', 'space0']}
            px={['space4', null, 'space0']}
            zIndex={1}
          >
            <Tag>{info.tag}</Tag>
            <H2
              fontWeight="regular"
              fontSize={['125', '125', '200']}
              lineHeight={['200', '200', '250']}
            >
              {info.title}
            </H2>
            <Box>
              <P fontSize="87.5">{info.author.name}</P>
              <P fontSize="87.5">{info.author.role}</P>
            </Box>
            <Button variant="outline" href={info.cta.url}>
              {info.cta.text}
            </Button>
          </Box>
        </Box>
        <Box flex="1 1 100%">
          <Image
            loader={cloudflareLoader}
            alt={image.alt}
            src={image.src}
            width={600}
            height={600}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </Box>
      </Wide>
    </Section>
  );
};

export default HalfColorBlock;
