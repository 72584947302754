import { DecoratedNextImage } from 'src/components/image/DecoratedNextImage';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Section, Wide } from '../layout';
import { Motion } from '../Motion';
import type { HeroLayoutT } from './Hero';

export const RowHero = ({
  image,
  motion,
  theme,
  invertLayout,
  imageMaxWidth,
  children,
  ...rest
}: HeroLayoutT) => (
  <Section theme={theme} justifyContent="center" {...rest}>
    <Wide flexDirection={['column', 'row']} alignItems="center">
      {!invertLayout && children}
      {(image || motion) && (
        <Box
          maxWidth={imageMaxWidth}
          flex={['1 1 auto', '1 1 50%']}
          ml={!invertLayout ? ['space0', 'space4'] : 'space0'}
          mr={invertLayout ? ['space0', 'space4'] : 'space0'}
          mt={[!invertLayout ? 'space8' : 'space0', 'space0']}
          mb={[invertLayout ? 'space8' : 'space0', 'space0']}
        >
          {image && <DecoratedNextImage {...image} priority sizes="100vw" />}
          {motion && <Motion {...motion} />}
        </Box>
      )}
      {invertLayout && children}
    </Wide>
  </Section>
);
