import React, { useState, type ReactNode } from 'react';
import Image from 'next/image';
import { useMatch } from '@mentimeter/ragnar-device';
import { Section } from 'src/ui/layout';
import { H2, Subheading } from 'src/ui/typography';
import Tag from 'src/components/Tag';
import cloudflareLoader from 'imageLoader';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';

interface DesktopImageBlockProps {
  tag: string;
  slides: {
    text: string;
    icon: ReactNode;
    desktop: { src: string; alt: string };
    mobile: { src: string; alt: string };
  }[];
  title: string;
  subtitle: string;
}

const DesktopImageBlock = ({
  tag,
  slides,
  title,
  subtitle,
}: DesktopImageBlockProps) => {
  const [activeButton, setActiveButton] = useState(0);
  const isBigDesktop = useMatch({ greaterThan: 3 });
  const isDesktop = useMatch({ greaterThan: 2 });
  return (
    <Section px={['space8', null, null, 6]} py="space6" pb="space14">
      <Tag>{tag}</Tag>
      <H2
        fontSize={['200', 7, '300']}
        my="space6"
        fontWeight="regular"
        textAlign="center"
      >
        {title}
      </H2>
      <Subheading
        fontWeight="regular"
        fontSize={['100', '112.5']}
        maxWidth="680px"
        textAlign="center"
        mb="space8"
      >
        {subtitle}
      </Subheading>
      <Box
        flexDirection="row"
        gap={2}
        justifyContent="center"
        alignItems="center"
      >
        {slides.map((slide, index) => (
          <Button
            key={slide.text + index}
            onClick={() => setActiveButton(index)}
            variant={activeButton === index ? 'outline' : undefined}
            extend={() => ({ fontWeight: 'regular' })}
          >
            {slide.icon}
            {isDesktop && slide.text}
          </Button>
        ))}
      </Box>
      <Box
        mt="space8"
        alignItems="center"
        justifyContent="center"
        borderRadius="4xl"
      >
        <Image
          loader={(params) => cloudflareLoader({ ...params, quality: 30 })} //it's only a tiny blurry portion of this image that is visible, so it's ok to have a lower quality
          src="https://static.mentimeter.com/static/images/DesktopBlockBackground.webp"
          alt=""
          width={1038}
          height={567}
          sizes="100vw"
          style={{ maxWidth: '100%', height: 'auto' }}
        />
        <Box
          position="absolute"
          top={['8px', '16px']} // space2, space4 but can't use kosmos because it was never converted and we will move to tailwind soon anyway
          right="0"
        >
          <Image
            loader={cloudflareLoader}
            src={slides[activeButton]?.desktop.src ?? ''}
            alt={slides[activeButton]?.desktop.alt ?? ''}
            width={1038}
            height={567}
            sizes="100vw"
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        </Box>
        {isBigDesktop && (
          <Box position="absolute" bottom="-50px" right="-130px">
            <Image
              loader={cloudflareLoader}
              src={slides[activeButton]?.mobile.src ?? ''}
              alt={slides[activeButton]?.mobile.alt ?? ''}
              width={320}
              height={600}
            />
          </Box>
        )}
      </Box>
    </Section>
  );
};

export default DesktopImageBlock;
